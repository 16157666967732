import Lottie from "lottie-react";
const service = ({ title, desc, img, disabled }) => {
    return (
        <div className="absbg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
            <div className="text-justify text-sm relative">
                {disabled && <p className="absolute m-auto text-center mt-0.5 text-sm text-gray-400 w-full italic">Coming Soon...</p>}
                {/* <img alt="card img" className="pt-2 rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} /> */}
                <Lottie autoplay={true} animationData={img} className="pt-2 rounded-t group-hover:scale-[1.15] h-50" />
                <h2 className="font-semibold my-4 text-2xl text-center">{title}</h2>
                <p className="text-md font-medium">
                    {desc}
                </p>
            </div>
        </div>
    )
}

export default service;