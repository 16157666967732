import { useEffect, useState } from 'react';

const useContent = () => {
    const [content, setContent] = useState({});

    useEffect(async () => {
        const content = (await import('../config/config.json')).default;
        setContent(content);
    }, []);

    return content;
}

export {
    useContent
}