import React, { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Cart } from '../cart';
import { storeContext } from '../../store/store';

const NavLinks = () => {
    const { isOpen, setOpen, cart: products } = useContext(storeContext);
    const productsCount = products?.length;

    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about">
                About
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                Services
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#products">
                Products
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/contact#contact">
                Contact Us
            </HashLink>
            {productsCount > 0 && <a onClick={() => setOpen(true)} className="hidden lg:inline-block px-4 font-extrabold text-gray-500 hover:text-blue-900">
                <Cart height="28" width="28" className={`inline-flex group-hover:scale-[1.15] transition duration-500 ease-in-out ${isOpen ? ' text-blue-900' : ''}`} />
                <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">{productsCount}</span>
            </a>}
        </>
    )
}

export default NavLinks;
