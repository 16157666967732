import Lottie from "lottie-react";
import fail from '../images/fail.json';
import { useNavigate } from "react-router-dom";
import { useRef, useEffect, useContext } from "react";
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { storeContext } from '../store/store';
import { contactInfomationKey } from "./Cart";

const Fail = () => {
    const navigate = useNavigate();
    const { setOpen } = useContext(storeContext);
    // const ref = useRef(null);
    const { email, phone, name } = JSON.parse(localStorage.getItem(contactInfomationKey));

    useEffect(() => {

        // clearCart();
        //     setTimeout(() => {
        //         ref?.current?.play();
        //     }, 500)
    }, [])


    return (
        <>
            <NavBar />
            <div className="h-screen flex items-center justify-center flex-col">
                <Lottie autoplay={true} animationData={fail} loop={4} className="h-[200px]" />
                <div className="text-center">
                    {name && <span className="text-xl pb-2">{name},</span>}
                    <h2 className="text-xl">Payment Declined</h2>
                    <span className="text-sm text-gray-500">
                        PLEASE TRY AGAIN LATER OR CONTACT SUPPORT
                    </span>
                    <div className="flex items-center justify-center hover:cursor-pointer text-lg text-gray-400 hover:text-gray-800">
                        <span className="" onClick={() => navigate('/')}>Home</span>
                        <svg className="w-4 h-4 ml-1 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export {
    Fail
}