import React, { useContext, useEffect } from 'react';
import Product from './product/product';
import { useCart } from '../hooks/useCart';
import { storeContext } from '../store/store';

const Portfolio = ({ id }) => {
    
    // const { products = [] } = useContent();
    const { addToCart, setOpen, products } = useContext(storeContext);
    
    return (
        <div id={id} className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4" >
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{id || 'Products'}</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                    </div>

                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className={`grid sm:grid-cols-2 lg:grid-cols-4 gap-3`}>
                            {
                                products.map((product, ind) => <Product
                                    price={product.price}
                                    onClick={() => { addToCart(product); setOpen(true) }}
                                    key={ind}
                                    payment_link={product.payment_link}
                                    imageSrc={product.imageSrc}
                                    title={product.title}
                                    description={product.description}
                                />)
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Portfolio;