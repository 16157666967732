import { useEffect, useState } from "react";

let isOpen = false;

const useModal = (state) => {
    const [open, setOpen] = useState(state);

    useEffect(() => {
        if (state !== undefined) {
            isOpen = state;
        }
    }, []);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return [
        open, setOpen
    ]
}

export {
    useModal
}