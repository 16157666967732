import { useEffect, useState } from "react"

const PRODUCTS_KEY = 'productsKey';

const useCart = () => {
    const [cart, setCart] = useState([]);

    const addToCart = (product) => {
        // const data = [...cart, product];
        const data = [product];
        setCart(data);
        persist(data);
    }

    const removeFromCart = (id) => {
        const index = cart.findIndex((el) => el.price_id === id);
        if (index !== -1) {
            cart.splice(index, 1);
            const data = [...cart];
            setCart(data);
            persist(data);
        }
    }

    const getCart = () => {
        return cart;
    }

    const clearCart = () => {
        setCart([]);
        persist([]);
    }

    const persist = (data) => {
        const str = JSON.stringify(data);
        localStorage.setItem(PRODUCTS_KEY, str);
    }

    const load = () => {
        try {
            const items = localStorage.getItem(PRODUCTS_KEY);
            if (!items) {
                persist([]);
            }
            else {
                const data = JSON.parse(items);
                setCart(data);
            }
        }
        catch (e) {
        }
    }

    useEffect(() => {
        load();
    }, []);

    return {
        addToCart, removeFromCart, getCart, cart, clearCart, setCart
    }
}

export {
    useCart
}