import React, { useContext, useEffect } from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Products from '../components/Products';
import Services from '../components/Services';
import { ProceedToCheckout } from './Cart';
import { storeContext } from '../store/store';
import { useContent } from '../hooks/useContent';

const Home = () => {
    const { addToCart, setOpen,products } = useContext(storeContext);

    const handler = () => {
        let qry;
        if (document.location.search) {
            qry = document.location.search;
        }
        else if (document.location.hash) {
            qry = document?.location?.hash?.split('#')?.[1].split('?')?.[1];
        }
        const query = new URLSearchParams(qry);
        const productAmout = query.get('product');
        if (productAmout) {
            const productId = products?.findIndex(el => el.price_id == productAmout);
            if (productId > -1) {
                addToCart(products[productId]);
                setOpen(true);
            }
        }
    }

    useEffect(() => {
        handler();
    }, [products]);

    return (
        <>
            <ProceedToCheckout />
            <Hero />
            <Intro />
            <Services />
            <Products id='products' />
            {/* <Clients /> */}
            <Cta />
            <Footer />
        </>

    )
}

export default Home;

