import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { storeContext } from './store/store';
import "aos/dist/aos.css";
import './index.css';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import { useContent } from './hooks/useContent';
import { useCart } from './hooks/useCart';
import { Success } from './pages/Success';
import { Fail } from './pages/Fail';

function App() {
  const data = useContent();
  const { name, doc_title } = data;
  useDocTitle(doc_title);

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  const [isOpen, setOpen] = useState(false);
  const { cart, addToCart, removeFromCart, clearCart, setCart } = useCart();
  const [products, setProducts] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ENDPOINT}/products?filters[mid][$eq]=1&sort[0]=price_id:asc`)
      .then(res => {
        const products = res?.data?.data?.map(({ attributes }) => attributes) || [];
        setProducts(products);
      })
  }, []);

  return (
    <>
      <storeContext.Provider value={{ isOpen, setOpen, cart, products, addToCart, removeFromCart, clearCart }}>
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/success" element={<Success />} />
              <Route path="/fail" element={<Fail />} />

            </Routes>
          </ScrollToTop>
        </Router>
      </storeContext.Provider>
    </>
  );
}


export default App;
