const product = ({ title, description, imageSrc, price, onClick }) => {
    return (
        <div className="max-w-sm relative rounded overflow-hidden shadow-lg">
            <img className="w-full" src={imageSrc} alt="Sunset in the mountains" />
            <div className="px-6 pt-2">
                <div className="font-bold text-xl h-[50px]">{title}</div>
                <p className="text-l italic text-blue-800 text-center my-2 underline font-bold">{price}</p>
                
                <ul className="md:h-[320px] lg:h-[430px] xl:h-[350px]">
                    {description.map((el,ind) => <li key={ind} className="list-disc">{el}</li>)}
                </ul>
                
            </div>
            <div className="flex justify-center px-3">
                <button onClick={onClick} className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                    Add to cart
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>
        </div>

    )
}

export default product;