import { Fragment, useContext, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { storeContext } from '../store/store';
import Modal from '../components/modal/modal';
import { useModal } from '../hooks/useModal';

export const contactInfomationKey = 'CONTACT_INFORMATION';

export const ContactFormDetails = ({ open, setOpen }) => {
    const [contact, setContact] = useState({ name: '', email: '', phone: '' });
    const { cart: products } = useContext(storeContext);
    const form = useRef(null);
    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (form.current.checkValidity()) {
            localStorage.setItem(contactInfomationKey, JSON.stringify(contact));
            setOpen(false);
            // TODO: redirect checkout page
            window.location.href = products?.[0].payment_link;
        }
    }

    return (
        <Modal open={open} setOpen={setOpen} className="" title={'Contact Information'}>
            <div className='h-full flex flex-col'>
                <form onSubmit={onSubmitHandler} className='w-full max-w-lg flex-1' id='contact' ref={form}>
                    <div className='my-3'>
                        <label className="block text-gray-700 text-sm font-bold mb-2 mr-3" htmlFor="name">
                            Name
                        </label>
                        <input
                            onChange={(e) => { const { name, ...rest } = contact; setContact({ name: e.target.value, ...rest }) }}
                            type='text' id="name"
                            placeholder="Name"
                            name="name"
                            value={contact.name}
                            required="required"
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline' />
                    </div>

                    <div className='my-3'>
                        <label className="block text-gray-700 text-sm font-bold mb-2 mr-3" htmlFor="email">
                            Email
                        </label>
                        <input
                            onChange={(e) => { const { email, ...rest } = contact; setContact({ email: e.target.value, ...rest }) }}
                            type='email'
                            id='email'
                            placeholder="Email"
                            value={contact.email}
                            name="email"
                            required="required"
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline' />
                    </div>

                    <div className='my-3'>
                        <label className="block text-gray-700 text-sm font-bold mb-2 mr-3" htmlFor="phone">
                            Phone
                        </label>
                        <input
                            onChange={(e) => { const { phone, ...rest } = contact; setContact({ phone: e.target.value, ...rest }) }}
                            type='number'
                            id='phone'
                            name="phone"
                            placeholder="Phone"
                            value={contact.phone}
                            required="required"
                            className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline' />
                    </div>
                </form>

                <button type='submit' form='contact' className='flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 '>
                    Next
                </button>
            </div>
        </Modal>
    )
}

export const ProceedToCheckout = () => {
    const [isContactInfoOpen, openContactInfo] = useModal(false);
    const { isOpen, setOpen, removeFromCart, cart: products } = useContext(storeContext);
    const total = products?.reduce((acc, cur) => {
        const firstChar = cur?.price?.[0];
        const [currency, price] = cur?.price.split(firstChar);
        acc += Number(price);
        return acc;
    }, 0);

    // TODO: fetch the payment links for the particular merchant

    const checkoutOnClickHandler = () => {
        const email = localStorage.getItem(contactInfomationKey);
        if (!email) {
            openContactInfo(true);
        }
        // TODO: redirect checkout page
        else {
            window.location.href = products?.[0].payment_link;
        }

    }

    return (
        <>
            <ContactFormDetails open={isContactInfoOpen} setOpen={openContactInfo} />

            <Transition.Root show={isOpen} as={Fragment} className="z-1">
                <Dialog className="relative z-10" onClose={setOpen} id="modal">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden z-30">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                            <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900">Shopping cart</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="absolute -inset-0.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <div className="flow-root">
                                                        <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                            {products?.map((product, ind) => {
                                                                return (
                                                                    <li key={ind} className="flex py-6">
                                                                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                            <img
                                                                                src={product.imageSrc}
                                                                                alt={product.imageAlt}
                                                                                className="h-full w-full object-cover object-center"
                                                                            />
                                                                        </div>

                                                                        <div className="ml-4 flex flex-1 flex-col">
                                                                            <div>
                                                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                                                    <h3>
                                                                                        <a href={product.href}>{product.name}</a>
                                                                                    </h3>
                                                                                    <p className="ml-4">{product.price}</p>
                                                                                </div>
                                                                                <p className="mt-1 text-sm text-gray-500">{product.title}</p>
                                                                            </div>
                                                                            <div className="flex flex-1 items-end justify-between text-sm">
                                                                                <p className="text-gray-500">Qty {product.quantity}</p>

                                                                                <div className="flex">
                                                                                    <button
                                                                                        onClick={() => {
                                                                                            removeFromCart(product.price_id);
                                                                                            if (!product.length) {
                                                                                                setOpen(false)
                                                                                            }
                                                                                        }}
                                                                                        type="button"
                                                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                                    >
                                                                                        Remove
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                    <p>Subtotal</p>
                                                    <p>${total?.toFixed(2)}</p>
                                                </div>
                                                {/* <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p> */}
                                                <div className="mt-6">
                                                    <button
                                                        onClick={checkoutOnClickHandler}
                                                        disabled={products?.length === 0}
                                                        href="#"
                                                        className={`flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 ${products?.length === 0 ? 'bg-gray-300 cursor-not-allowed hover:bg-gray-300' : ''}`}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>
                                                <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                    <p>
                                                        or{' '}
                                                        <button
                                                            type="button"
                                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            Continue Shopping
                                                            <span aria-hidden="true"> &rarr;</span>
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}