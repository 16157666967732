import { useEffect, useState } from "react";

const useDocTitle = title => {
  const [doctitle, setDocTitle] = useState(title);
  
  useEffect(() => {
    document.title = title;
  }, [title]);

  return [doctitle, setDocTitle];
};

export { useDocTitle };